<template>
  <PageContentLayoutOnly role="region" surveys>
    <div class="level pr-5">
      <div class="pt-5 level-left level-item title">{{ $t('suggestion.detailsTitle') }}</div>
    </div>

    <LinksMenu
      aria-label="suggestion tabs"
      :tabs="tabs"
      prefix="suggestionTabs."
      :styles="{
        ul: {
          width: '140px'
        }
      }"
    >
      <section :style="{ overflowX: 'auto', flexGrow: 2, marginTop: '-20px' }">
        <SlideTransition :name="transitionName">
          <keep-alive>
            <router-view :key="$route.name" />
          </keep-alive>
        </SlideTransition>
      </section>
    </LinksMenu>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import SlideTransition from '@/components/transitions/Slide.vue'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'

import { data } from './keys/data'
import { watch } from './keys/watch'

import LinksMenu from '@/components/menus/Links'

export default {
  components: {
    SlideTransition,
    PageContentLayoutOnly,
    LinksMenu
  },

  computed: {
    ...mapState({
      unit: state => state.hoa.unit
    })
  },

  data,
  mounted() {},
  watch,

  i18n: {
    messages: {
      en: { suggestion: { detailsTitle: 'Suggestion Detail' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.surveys {
  .owner-avatar {
    margin: 1em auto 2em;
  }

  .card {
    box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

    .card {
      border: 0 !important;
    }
  }

  .avatar-img {
    border: 1px solid #e7e8f2;
    padding: 0.25em;
  }

  .address-card {
    h5,
    h6 {
      margin: 0;
    }
  }

  .details-img {
    img {
      border-radius: 0.5em;
    }
  }
}
</style>
