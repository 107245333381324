export const data = function() {
  return {
    isDebug: true,
    loading: false,
    tabs: [
      { name: 'suggestionDetail', label: 'Detail' },
      { name: 'suggestionConversations', label: 'Conversations' }
    ],
    styles: {
      mainContainer: {
        minHeight: '1000px'
      }
    },
    transitionName: 'slide-down'
  }
}
